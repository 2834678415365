import React from "react";
import ReactDOM from "react-dom";
import {Modal} from 'react-bootstrap';
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente modal base
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const CustomModal = (props) => {
  return ReactDOM.createPortal(
    <React.Fragment>
      <Modal show={props.isOpen} keyboard={false} size={props.modalSize} onHide={() => false}>
        <Modal.Header>
            <Modal.Title>{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </Modal>
    </React.Fragment>,
    document.getElementById("modal")
  );
};

export default CustomModal;

export const urls = {
    
    LOGIN_REQUEST:"/login",
    LOGIN_LOGOUT_REQUEST:"/login/cerrarsesion",
    LOGIN_REVALIDATE_REQUEST:"/login/revalidar",
    LOGIN_RESET:"/RecuperarClave",
    SEND_EMAIL:"/email",

    /** BOARD FAIR ADMIN URLS */
    REQUEST_SYSTEMPARAM_ID:"/parametrosistema/buscarporid",
    REQUEST_SYSTEMPARAM:"/parametrosistema",
    REQUEST_STANDCATEGORY_LIST:"/categoriastand/listar",
    REQUEST_STANDCATEGORY_ID:"/categoriastand/buscarporid",
    REQUEST_STANDCATEGORY:"/categoriastand",
    REQUEST_STAND_LIST:"/stand/listar",
    REQUEST_STAND_LISTBYCATEGORY:"/stand/listarporcategoria",
    REQUEST_STAND_ID:"/stand/buscarporid",
    REQUEST_STAND:"/stand",
    REQUEST_STANDPHONE_LIST:"/standtelefono/listar",
    REQUEST_STANDPHONE_ID:"/standtelefono/buscarporid",
    REQUEST_STANDPHONE:"/standtelefono",
    REQUEST_STANDSOCIALNETWORK_LIST:"/standredsocial/listar",
    REQUEST_STANDSOCIALNETWORK_ID:"/standredsocial/buscarporid",
    REQUEST_STANDSOCIALNETWORK:"/standredsocial",
    REQUEST_STANDMULTIMEDIA_LIST:"/standmultimedia/listar",
    REQUEST_STANDMULTIMEDIA_ID:"/standmultimedia/buscarporid",
    REQUEST_STANDMULTIMEDIA:"/standmultimedia",
    REQUEST_STANDMEMBER_LIST:"/standintegrante/listar",
    REQUEST_STANDMEMBER_ID:"/standintegrante/buscarporid",
    REQUEST_STANDMEMBER:"/standintegrante",
    REQUEST_STANDVISIT_LIST:"/standvisita/listar",
    REQUEST_STANDVISIT_ID:"/standvisita/buscarporid",
    REQUEST_STANDVISIT:"/standvisita",
    REQUEST_SURVEY_LIST:"/encuesta/listar",
    REQUEST_SURVEY_LIST2:"/encuesta/listarporusuario",
    REQUEST_SURVEY_ID:"/encuesta/buscarporid",
    REQUEST_SURVEY:"/encuesta",
    REQUEST_TALK_LIST:"/charla/listar",
    REQUEST_TALK_ID:"/charla/buscarporid",
    REQUEST_TALK:"/charla",
    REQUEST_TALKMEMBER_LIST:"/charlaexpositor/listar",
    REQUEST_TALKMEMBER_ID:"/charlaexpositor/buscarporid",
    REQUEST_TALKMEMBER:"/charlaexpositor",
    REQUEST_TALKVISIT_LIST:"/charlavisita/listar",
    REQUEST_TALKVISIT_ID:"/charlavisita/buscarporid",
    REQUEST_TALKVISIT:"/charlavisita",

    /** BOARD CATALOGS URLS */
    REQUEST_GENDER_LIST:"/genero/listar",
    REQUEST_GENDER_ID:"/genero/buscarporid",
    REQUEST_GENDER:"/genero",
    REQUEST_STUDYCENTER_LIST:"/centroestudio/listar",
    REQUEST_STUDYCENTER_ID:"/centroestudio/buscarporid",
    REQUEST_STUDYCENTER:"/centroestudio",
    REQUEST_VISITORTYPE_LIST:"/tipovisitante/listar",
    REQUEST_VISITORTYPE_ID:"/tipovisitante/buscarporid",
    REQUEST_VISITORTYPE:"/tipovisitante",
    REQUEST_PROVINCIA_LIST:"/provincia/listar",
    REQUEST_PROVINCIA_ID:"/provincia/buscarporid",
    REQUEST_PROVINCIA:"/provincia",
    REQUEST_CANTON_LIST:"/canton/listar",
    REQUEST_CANTON_ID:"/canton/buscarporid",
    REQUEST_CANTON:"/canton",
    REQUEST_DISTRITO_LIST:"/distrito/listar",
    REQUEST_DISTRITO_ID:"/distrito/buscarporid",
    REQUEST_DISTRITO:"/distrito",
    REQUEST_TALKSTATUS_LIST:"/estadocharla/listar",
    REQUEST_TALKSTATUS_ID:"/estadocharla/buscarporid",
    REQUEST_TALKSTATUS:"/estadocharla",
    REQUEST_PHONETYPE_LIST:"/tipotelefono/listar",
    REQUEST_PHONETYPE_ID:"/tipotelefono/buscarporid",
    REQUEST_PHONETYPE:"/tipotelefono",
    REQUEST_SOCIALNETWORKTYPE_LIST:"/tiporedsocial/listar",
    REQUEST_SOCIALNETWORKTYPE_ID:"/tiporedsocial/buscarporid",
    REQUEST_SOCIALNETWORKTYPE:"/tiporedsocial",
    REQUEST_GEOGRAPHICREGION_LIST:"/regiongeografica/listar",
    REQUEST_GEOGRAPHICREGION_ID:"/regiongeografica/buscarporid",
    REQUEST_GEOGRAPHICREGION:"/regiongeografica",
    REQUEST_MULTIMEDIATYPE_LIST:"/tipomultimedia/listar",
    REQUEST_MULTIMEDIATYPE_ID:"/tipomultimedia/buscarporid",
    REQUEST_MULTIMEDIATYPE:"/tipomultimedia",
    REQUEST_SURVEYOPTION_LIST:"/opcionencuesta/listar",
    REQUEST_SURVEYOPTION_ID:"/opcionencuesta/buscarporid",
    REQUEST_SURVEYOPTION:"/opcionencuesta",

    /** BOARD REPORT URLS */
    REQUEST_USERREPORT_LIST:"/reporte/listarVWUSUARIOREPORTE001",
    REQUEST_STANDREPORT_LIST:"/reporte/listarVWSTANDVISITASREPORTE002",
    REQUEST_TALKREPORT_LIST:"/reporte/listarVWCHARLAVISITASREPORTE003",
    REQUEST_USERBINNACLE_LIST:"/reporte/listarVWUSUARIOBITACORAREPORTE004",
    REQUEST_USERSURVEY_LIST:"/reporte/listarVWUSUARIOENCUESTAREPORTE005",

    /** BOARD SEG URLS */
    REQUEST_USER_LIST:"/usuario/listar",
    REQUEST_USER_ID:"/usuario/buscarporid",
    REQUEST_USER_IDENTIFICATION:"/usuario/buscarporidentificacion",
    REQUEST_USER_PASS:"/usuario/cambioclave",
    REQUEST_USER:"/usuario",
    REQUEST_USERROL_LIST:"/usuariorol/listar",
    REQUEST_USERROL_ID:"/usuariorol/buscarporid",
    REQUEST_USERROL_CHANGEROL:"/usuariorol/cambiarrol",
    REQUEST_USERROL:"/usuariorol",
    REQUEST_ROL_LIST:"/rol/listar",
    REQUEST_ROL_ID:"/rol/buscarporid",
    REQUEST_ROL_AUTHORITIES_BY_ID:"/rol/obtenerautoridades",
    REQUEST_ROL:"/rol",
    REQUEST_AUTHORITY_LIST:"/autoridad/listar",
    REQUEST_AUTHORITY_ID:"/autoridad/buscarporid",
    REQUEST_AUTHORITY:"/autoridad",
    REQUEST_ROLAUTHORITY_LIST:"/rolautoridad/listar",
    REQUEST_ROLAUTHORITY_ID:"/rolautoridad/buscarporid",
    REQUEST_ROLAUTHORITY:"/rolautoridad",


}
import React from "react";
import { constant } from "../../utils/Constant";
import SweetAlert from "react-bootstrap-sweetalert";
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente para manejo de mensaje de validacion
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const MessageManager = (props) => {
  let responseMsg;
  if (props.type === constant.SUCCESS_MSG) {
    responseMsg = (
      <SuccessMessage
        msg={props.msg}
        details={props.details}
        onConfirm={props.onConfirm}
      />
    );
  } else {
    responseMsg = (
      <ErrorMessage
        msg={props.msg}
        details={props.details}
        onConfirm={props.onConfirm}
      />
    );
  }
  return responseMsg;
};

const SuccessMessage = ({ msg, details, onConfirm }) => (
  <SweetAlert
    title={msg}
    success
    onConfirm={onConfirm}
    confirmBtnBsStyle="success"
  >
    {details}
  </SweetAlert>
);

const ErrorMessage = ({ msg, details, onConfirm }) => (
  <SweetAlert
    title={msg}
    error
    onConfirm={onConfirm}
    confirmBtnBsStyle="error"
  >
    {details}
  </SweetAlert>
);

export default MessageManager;

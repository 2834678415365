import {
  SVFindRequest,
  SVFindByIdRequest,
  SVSaveRequest,
  SVUpdateRequest,
  SVDeleteRequest,
} from "../../../services/boardServices/fairAdmin/SVTalkMemberRequest";
import { properties } from "../../../utils/Properties_es";
import * as dataActions from "../../DataActions";
import * as utilActions from "../../UtilActions";

export const fetchDataAction = (resetAllMsg, childData, talkId, page) => {
  return async (dispatch) => {
    if (resetAllMsg) {
      dispatch(utilActions.resetAllChildMsg());
    }
    dispatch(utilActions.isLoading(true));
    try {
      // if (childData) {
      //   //call request
      //   const returnData = await SVFindRequest2(null, 1, true);
      //   //save data state
      //   await dispatch(dataActions.fetchDataChild2(returnData));
      // }
      //call request
      const returnData = await SVFindRequest(talkId, page, false);
      //save data state
      await dispatch(dataActions.fetchDataChild(returnData));
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setChildErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const fetchByIDDataAction = (id) => {
  return async (dispatch) => {
    dispatch(utilActions.resetAllChildMsg());
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVFindByIdRequest(id);
      //save dataid state
      await dispatch(dataActions.fetchIdChildData(returnData));
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setChildErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const saveAction = (data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllChildMsg());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVSaveRequest(data);
      //save user state
      await dispatch(dataActions.saveData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setChildSuccessMsg(
            properties.com_feria_label_request_save
          )
        );
      } else {
        dispatch(
          utilActions.setChildErrorMsg(
            properties.com_feria_label_talkmember_save_error
          )
        );
      }

      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setChildErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const updateAction = (id, data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllChildMsg());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVUpdateRequest(id, data);
      //save user state
      await dispatch(dataActions.updateData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setChildSuccessMsg(
            properties.com_feria_label_request_update
          )
        );
      } else {
        dispatch(
          utilActions.setChildErrorMsg(
            properties.com_feria_label_talkmember_update_error
          )
        );
      }

      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setChildErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const deleteAction = (id) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllChildMsg());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVDeleteRequest(id);
      //save user state
      await dispatch(dataActions.deleteData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setChildSuccessMsg(
            properties.com_feria_label_request_delete
          )
        );
      } else {
        dispatch(
          utilActions.setChildErrorMsg(
            properties.com_feria_label_talkmember_delete_error
          )
        );
      }
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setChildErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

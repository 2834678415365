import {fromJS} from 'immutable';
import {Map as map} from 'immutable';
import {actionsType} from '../actions/utils/ActionType'

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description Reducer encargado de manejo de datos de las llamadas a la api
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/

const initialState = fromJS({
    data:map(),
    dataId:map(),
    childDataKeys:map(),
    childData:map(),
    childData2:map(),
    childData3:map(),
    childData4:map(),
    childData5:map(),
    chilDataId:map(),
    dataUserSurvey:map(),
    dataSurvey:map(),
    dataTalk:map(),
    dataSave:0,
    dataUpdate:0,
    dataDelete:0,
});

function data(state=initialState,action){

  switch (action.type) {
      case actionsType.FETCH_DATA: {
          return state.merge({data:action.payload.data})
      }
      case actionsType.FETCH_DATA_CHILD_KEYS: {
        return state.merge({childDataKeys:action.payload.data})
      }
      case actionsType.FETCH_DATA_CHILD: {
        return state.merge({childData:action.payload.data})
      }
      case actionsType.FETCH_DATA_CHILD2: {
        return state.merge({childData2:action.payload.data})
      }
      case actionsType.FETCH_DATA_CHILD3: {
        return state.merge({childData3:action.payload.data})
      }
      case actionsType.FETCH_DATA_CHILD4: {
        return state.merge({childData4:action.payload.data})
      }
      case actionsType.FETCH_DATA_CHILD5: {
        return state.merge({childData5:action.payload.data})
      }
      case actionsType.FETCHID_DATA: {
        return state.merge({dataId:action.payload.data})
      }
      case actionsType.FETCHID_DATACHILD: {
        return state.merge({chilDataId:action.payload.data})
      }
      case actionsType.SAVE_DATA: {
        return state.merge({dataSave:action.payload.exitoso})
      }
      case actionsType.UPDATE_DATA: {
        return state.merge({dataUpdate:action.payload.exitoso})
      }
      case actionsType.DELETE_DATA: {
        return state.merge({dataDelete:action.payload.exitoso})
      }
      case actionsType.RESET_CHILD_DATA: {
        return state.merge({childData:map(),childData2:map(),chilDataId:map()})
      }
      case actionsType.FETCH_DATA_SURVEY: {
        return state.merge({dataSurvey:action.payload.data})
      }
      case actionsType.FETCH_DATA_USERSURVEY: {
        return state.merge({dataUserSurvey:action.payload.data})
      }
      case actionsType.FETCH_DATA_TALK: {
        return state.merge({dataTalk:action.payload.data})
      }
      case actionsType.RESET_DATA: {
        return state = initialState;
      }
      default:
          return state;
      
  }

}

export default data;
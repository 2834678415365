import {
  SVDeleteRequest,
  SVFindByIdRequest,
  SVFindRequest,
  SVSaveRequest,
  SVUpdateRequest,
} from "../../../services/boardServices/fairAdmin/SVTalkRequest";
import { SVFindRequest as SVFindRequest2 } from "../../../services/boardServices/generalMaintenance/catalogs/SVTalkStatusRequest";
import { properties } from "../../../utils/Properties_es";
import * as dataActions from "../../DataActions";
import * as utilActions from "../../UtilActions";

export const fetchDataAction = (resetAllMsg, childData, filter, page) => {
  return async (dispatch) => {
    if (resetAllMsg) {
      dispatch(utilActions.resetAllMsg());
    }
    dispatch(utilActions.isLoading(true));
    try {
      //llamo la data de los catalogo que necesito
      if (childData) {
        
        // Call talks status
        const returnTalkStatusData = await SVFindRequest2("", 0, true);
        const childDataKeys = {
          talkstatus: returnTalkStatusData.Data,
        };

        //save data child keys
        await dispatch(dataActions.fetchDataChildKeys(childDataKeys));
      }
      //call request
      const returnData = await SVFindRequest(filter, page, false);
      //save fetchdata state
      await dispatch(dataActions.fetchData(returnData));
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const fetchByIDDataAction = (id) => {
  return async (dispatch) => {
    dispatch(utilActions.resetAllMsg());
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVFindByIdRequest(id);
      //save dataid state
      await dispatch(dataActions.fetchIdData(returnData));
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const saveAction = (data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());
    dispatch(utilActions.closeModal());
    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVSaveRequest(data);
      //save state
      await dispatch(dataActions.saveData(returnData));

      //set loading
      dispatch(utilActions.isLoading(false));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setSuccessMsg(properties.com_feria_label_request_save)
        );
      } else {
        dispatch(
          utilActions.setErrorMsg(properties.com_feria_label_talk_save_error)
        );
      }
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const updateAction = (id, data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());
    dispatch(utilActions.closeModal());
    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVUpdateRequest(id, data);
      //save update state
      await dispatch(dataActions.updateData(returnData));

      //set loading
      dispatch(utilActions.isLoading(false));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setSuccessMsg(properties.com_feria_label_request_update)
        );
      } else {
        dispatch(
          utilActions.setErrorMsg(properties.com_feria_label_talk_update_error)
        );
      }
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const deleteAction = (id) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //close modal
    dispatch(utilActions.closeModal());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVDeleteRequest(id);
      //save delete state
      await dispatch(dataActions.deleteData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setSuccessMsg(properties.com_feria_label_request_delete)
        );
      } else {
        dispatch(
          utilActions.setErrorMsg(properties.com_feria_label_talk_delete_error)
        );
      }
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

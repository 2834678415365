export const validateFieldStyle = (applyStyle, fieldError) => {
  let style = "";
  if (applyStyle) {
    style = "is-valid";
    if (fieldError) {
      style = "is-invalid";
    }
  }
  return style;
};

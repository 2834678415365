import React from "react";
import { properties } from "../../utils/Properties_es";
import Modal from "./CustomModal";
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente modal para manejo de eliminar registros
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const DeleteModal = (props) => (
  <Modal
    isOpen={props.isOpen}
    modalSize={props.modalSize}
    modalTitle={properties.com_feria_label_modal_delete_title}
  >
    <div className="row">
      <div className="col-12 text-center">
        <label>{props.data}</label>
      </div>
    </div>
    <div className="modal-footer">
      <button
        className="btn btn-success"
        id="acceptModal"
        onClick={props.handleModal}
      >
        {properties.com_feria_label_button_accept_title}
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        id="cancelModal"
        onClick={props.handleCancelModal}
      >
        {properties.com_feria_label_button_cancel_title}
      </button>
    </div>
  </Modal>
);

export default DeleteModal;

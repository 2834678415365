import axios from 'axios';
import { getItem } from './LocalStorageManager';

const privateFetch = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_CONTEXT
});

privateFetch.interceptors.request.use(
    async config=>{
      //validate uri to send token
      const {origin} = new URL(config.baseURL + config.url);
      const allowedOrigins = [process.env.REACT_APP_BASE_URL];
      const expiresAt = await getItem("expiresAt");
      const token = await getItem("token");
      let valid = new Date().getTime() / 1000 < expiresAt;
      if(allowedOrigins.includes(origin) && valid){
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    // error=>{
    //   return Promise.reject(error);
    // }
  );

export { privateFetch };
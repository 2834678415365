import { fromJS } from "immutable";
import { Map as map } from "immutable";
import { actionsType } from "../actions/utils/ActionType";

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description Reducer para administracion general de paginas en la app
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/

/**
 *
 * initialState
 *
 *  */
const initialState = fromJS({
  loading: false,
  pageLoading: false,
  successMsg: "",
  errorMsg: "",
  childSuccessMsg: "",
  childErrorMsg: "",
  security: {
    isAuthenticate: false,
    currentRole: "",
    currentAuthorities: {},
    userInfo: null,
  },
  systemParam: map(),
});

/**
 * pageManager reducer
 *
 * @param state
 * @param action
 */
function pageManager(state = initialState, action) {
  switch (action.type) {
    case actionsType.IS_LOADING:
      return state.merge({ loading: action.payload.value });
    case actionsType.IS_PAGE_LOADING:
      return state.merge({ pageLoading: action.payload.value });
    case actionsType.SUCCESSMSG:
      return state.merge({ errorMsg: "", successMsg: action.payload.value });
    case actionsType.ERRORMSG:
      return state.merge({ successMsg: "", errorMsg: action.payload.value });
    case actionsType.RESETMSG:
      return state.merge({ successMsg: "", errorMsg: "" });
    case actionsType.CHILDSUCCESSMSG:
      return state.merge({
        childErrorMsg: "",
        childSuccessMsg: action.payload.value,
      });
    case actionsType.CHILDERRORMSG:
      return state.merge({
        childSuccessMsg: "",
        childErrorMsg: action.payload.value,
      });
    case actionsType.CHILDRESETMSG:
      return state.merge({ childSuccessMsg: "", childErrorMsg: "" });
    //datos de autenticacion en caso de que el login sea correcto
    case actionsType.LOGIN:
      return state.merge({
        security: fromJS({
          userInfo: action.payload.userInfo,
        }),
      });
    //segunda etapa de login para activar el is =Auhenticated y agregar el rol actual y autorizaciones
    case actionsType.APPLYROL:
      return state.merge({
        security: fromJS({
          ...state.get("security").toJS(),
          isAuthenticate: true,
          currentRole: action.payload.rol,
          currentAuthorities: action.payload.authorities,
        }),
      });
    //etapa para cambio de roles en caso de tener mas de uno
    case actionsType.CHANGEROL:
      return state.merge({
        security: fromJS({
          ...state.get("security").toJS(),
          currentRole: action.payload.rol,
          currentAuthorities: action.payload.authorities,
        }),
      });
    case actionsType.CHANGEALLROL:
        return state.merge({
          security: fromJS({
            ...state.get("security").toJS(),
            currentRole: action.payload.rol,
            currentAuthorities: action.payload.authorities,
            userInfo:fromJS({
              ...state.get("security").get('userInfo').toJS(),
              roles:  action.payload.roles
            })
          }),
     });
    case actionsType.SEGURITYRELOAD:
      return state.merge({
        security: fromJS({
          userInfo: action.payload.userInfo,
          isAuthenticate: true,
          currentRole: action.payload.rol,
          currentAuthorities: action.payload.authorities,
        }),
        /*systemParam: fromJS(action.payload.systemParam),*/
      });
    case actionsType.LOADSYSTEMPARAM:
      return state.merge({ systemParam: action.payload.data });
    case actionsType.LOGOUT:
      return (state = initialState);
    default:
      return state;
  }
}

export default pageManager;

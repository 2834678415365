import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as utilActions from "../../actions/UtilActions";
import * as securityActions from "../../actions/SecurityActions";
import { useIdleTimer } from "react-idle-timer";
import SesionModal from "../utils/SesionModal";
import { useHistory } from "react-router-dom";

/*********************************************
 * REVISION 1.0
 * Fecha: 24/11/2020
 *
 * @description Manejador de sesion y timeout
 * @author lferrer
 * @version 1.0
 *
 *********************************************/
const IdleTimerContainer = (props) => {
  const history = useHistory();
  const sessionTimeoutRef = useRef(null);

  /**
   *
   * Evento de expiracion de sesion
   *
   * @author lferrer
   *
   */
  const onIdleSesion = async () => {
    if (props.isAuthenticate) {
      sessionTimeoutRef.current = setTimeout(() => {
        logOut();
      }, process.env.REACT_APP_SESION_TIMEOUT_WAIT);
      await props.utilActions.openModal("usersession", null);
    }
  };

  /**
   *
   * Revalidacion de sesion del usuario
   *
   * @author lferrer
   *
   */
  const revalidateSession = async () => {
    clearTimeout(sessionTimeoutRef.current);
    await props.securityActions.revalidateTokenAction({
      identification: props.userInfo.get("identification"),
    });
    idleTimerRef.reset();
  };

  /**
   * Evento de logOut
   *
   * @author lferrer
   *  */
  const logOut = async () => {
    clearTimeout(sessionTimeoutRef.current);
    await props.securityActions.logoutAction(null, {
      bitacoraId: props.userInfo.get("bitacoraId"),
      deleteBitacora: 0,
    });
    await props.utilActions.closeModal();
    history.push("/");
  };

  /**
   * Definicion de IdleTimer
   *
   * @author lferrer
   *  */
  const idleTimerRef = useIdleTimer({
    timeout: process.env.REACT_APP_SESION_TIMEOUT,
    onIdle: onIdleSesion,
    events: [],
    stopOnIdle: true,
    startOnMount: props.isAuthenticate ? true : false,
  });

  /**
   * Efecto para validar la autenticacion y activar el idleTimerRef
   *
   * @author lferrer
   *  */
  useEffect(() => {
    if (props.isAuthenticate) {
      console.log("start time: ", Date.now());
      idleTimerRef.reset();
    }
  }, [props.isAuthenticate, idleTimerRef]);

  return (
    <>
      {props.modalType === "usersession" && (
        <SesionModal
          isOpen={props.modalIsOpen}
          modalSize=""
          revalidateSession={revalidateSession.bind(this)}
          logOut={logOut.bind(this)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    //state segurity
    isAuthenticate: state
      .get("pageManager")
      .get("security")
      .get("isAuthenticate"),
    userInfo: state.get("pageManager").get("security").get("userInfo"),

    //modal states
    modalIsOpen: state.get("modal").get("isOpen"),
    modalType: state.get("modal").get("modalType"),
    objectSelect: state.get("modal").get("objectSelect"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    utilActions: bindActionCreators(utilActions, dispatch),
    securityActions: bindActionCreators(securityActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(IdleTimerContainer);

import pageManager from './PageManagerReducer';
import modal from './ModalReducer';
import data from './DataReducer';
import {combineReducers} from 'redux-immutable';
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description Reducer root 
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const rootReducer = combineReducers({
    pageManager,
    modal,
    data
});

export default rootReducer;
import {
  SVDeleteRequest,
  SVFindByIdRequest,
  SVSaveRequest,
  SVUpdateRequest,
} from "../../../services/boardServices/fairAdmin/SVSystemParamRequest";
import { saveSystemParam } from "../../../utils/LocalStorageManager";
import { properties } from "../../../utils/Properties_es";
import * as dataActions from "../../DataActions";
import * as utilActions from "../../UtilActions";

export const fetchByIDDataAction = (resetAllMsg,id) => {
  return async (dispatch) => {
    if (resetAllMsg) {
      dispatch(utilActions.resetAllMsg());
    }
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVFindByIdRequest(id);
      //save data state
      await dispatch(dataActions.fetchIdData(returnData));
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const systemParamAction = () => {
  return async (dispatch) => {
    try {
      dispatch(utilActions.isPageLoading(true));
      //call request
      const returnData = await SVFindByIdRequest(1,true);
      //save data state
      await dispatch(utilActions.setSystemParam(returnData));
      //save in localstore
      saveSystemParam(returnData);
      dispatch(utilActions.isPageLoading(false));
    } catch (error) {
      dispatch(utilActions.isPageLoading(false));
    }
  };
};

export const saveAction = (data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //close modal
    dispatch(utilActions.closeModal());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVSaveRequest(data);
      //save user state
      await dispatch(dataActions.saveData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setSuccessMsg(properties.com_feria_label_request_save)
        );
      } else {
        dispatch(
          utilActions.setErrorMsg(
            properties.com_feria_label_systemparam_save_error
          )
        );
      }

      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const updateAction = (id, data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //close modal
    dispatch(utilActions.closeModal());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVUpdateRequest(id, data);
      //save user state
      await dispatch(dataActions.updateData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setSuccessMsg(properties.com_feria_label_systemparam_request_update)
        );
      } else {
        dispatch(
          utilActions.setErrorMsg(
            properties.com_feria_label_systemparam_update_error
          )
        );
      }

      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const deleteAction = (id) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //close modal
    dispatch(utilActions.closeModal());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call request
      const returnData = await SVDeleteRequest(id);
      //save user state
      await dispatch(dataActions.deleteData(returnData));

      //msg
      if (returnData.Exitoso === 1 && returnData.Data) {
        dispatch(
          utilActions.setSuccessMsg(properties.com_feria_label_request_delete)
        );
      } else {
        dispatch(
          utilActions.setErrorMsg(
            properties.com_feria_label_systemparam_delete_error
          )
        );
      }
      //set loading
      dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};
import jwt_decode from "jwt-decode";
import localforage from "localforage";

/**
 * Almacena la informacion del token y usuario en el store
 *
 * @param data
 * @author lferrer
 */
export const saveLogin = async (data) => {
  if (data.Token && data.UserInfo) {
    const decodeToken = jwt_decode(data.Token);
    await setItem("token", data.Token);
    await setItem("expiresAt", decodeToken.exp);
    await setItem("userInfo", data.UserInfo);
    await setItem("roles", data.Roles);
  }
};

/**
 * limpia la informacion del token y usuario en el store
 *
 * @author lferrer
 */
export const clearLogin = async () => {
  await removeItem("token");
  await removeItem("expiresAt");
  await removeItem("userInfo");
  await removeItem("roles");
};

/**
 * Almacena la informacion del rol actual del usuario y sus
 * autorizadades
 *
 * @param data
 * @author lferrer
 */
export const saveCurrentRol = async (rol, authoritiesData) => {
  if (rol && authoritiesData.Data) {
    await setItem("currentRol", {
      code: rol.Codigo,
    });
    await setItem(
      "currentAuthorities",
      authoritiesData.Data
        ? authoritiesData.Data.map((a) => ({ Codigo: a.Codigo, Url: a.Url }))
        : null
    );
  }
};

/**
 * Almacena la informacion del cambio de rol con su autoridades
 *
 * @param data
 * @author lferrer
 */
export const saveChangeRol = async (rol, roles, authoritiesData) => {
  if (rol && roles && authoritiesData.Data) {
    await setItem("currentRol", {
      code: rol.Codigo,
    });
    await setItem("roles", roles);
    await setItem(
      "currentAuthorities",
      authoritiesData.Data
        ? authoritiesData.Data.map((a) => ({ Codigo: a.Codigo, Url: a.Url }))
        : null
    );
  }
};

/**
 * limpia la informacion del rol actual del usuario y sus
 * autorizadades
 *
 * @author lferrer
 */
export const clearCurrentRol = async () => {
  await removeItem("currentRol");
  await removeItem("currentAuthorities");
};

/**
 * Almacena la informacion de parametros del sistema
 *
 * @param data
 * @author lferrer
 */
export const saveSystemParam = async (systemParam) => {
  if (systemParam && systemParam.Data) {
    await setItem("systemParam", systemParam.Data);
  }
};


/**
 * limpia la informacion de los parametros del su
 *
 * @author lferrer
 */
export const clearSystemParam = async () => {
  await removeItem("systemParam");
};

/**
 * Agrega un item al store
 *
 * @param key
 * @param value
 * @author lferrer
 */
export const setItem = async (key, value) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion");
  });
};

/**
 * busca un item al store
 *
 * @param key
 * @author lferrer
 */
export const getItem = async (key) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion");
  });
};

/**
 * Elimina un item al store
 *
 * @param key
 * @author lferrer
 */
export const removeItem = async (key) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion");
  });
};

import React from "react";
import { properties } from "../../utils/Properties_es";
import Modal from "./CustomModal";

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente modal para validacion de sesion del usuario
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const SesionModal = (props) => (
  <Modal
    isOpen={props.isOpen}
    modalSize={props.modalSize}
    modalTitle={properties.com_feria_label_sesion_modal_title}
  >
    <div className="row justify-content-center">
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">{properties.com_feria_label_loading_title}</span>
        </div>
      </div>
    </div>
    <div className="row justify-content-center pt-2">
      <div className="col-12 text-center">
        <h5>{properties.com_feria_label_sesion_question}</h5>
      </div>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-primary"
        id="acceptModal"
        onClick={props.revalidateSession}
      >
        {properties.com_feria_label_button_accept_title}
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        id="exitModal"
        onClick={props.logOut}
      >
        {properties.com_feria_label_button_exit_title}
      </button>
    </div>
  </Modal>
);

export default SesionModal;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loading from "../../../../../components/utils/Loading";
import NavBar from "../../../../../components/utils/NavBar";
import * as talkActions from "../../../../../actions/boardActions/fairAdmin/TalkActions";
import * as utilActions from "../../../../../actions/UtilActions";
import * as dataActions from "../../../../../actions/DataActions";
import { bindActionCreators } from "redux";
import CustomPagination from "../../../../../components/utils/CustomPagination";
import MessageManager from "../../../../../components/utils/MessageManager";
import { constant } from "../../../../../utils/Constant";
import { properties } from "../../../../../utils/Properties_es";
import DeleteModal from "../../../../../components/utils/DeleteModal";
import TalkMemberModal from "../talkMember/TalkMemberModal";
import ViewImagenModal from "../../../../../components/utils/ViewImagenModal";
import AddOrUpdateTalkForm from "../../../../../components/dashboard/fairAdmin/room/talk/AddOrUpdateTalkForm";

/***************************************
 * REVISION 1.0
 * Fecha: 1/12/2020
 *
 * @description Talks Page
 * 
 * REVISION 1.1
 * Fecha: 23/05/2021
 *
 * @description Add talk image
 * 
 * @author aquiros, lferrer
 * @version 1.1
 *
 ***************************************/
const Talk = (props) => {
  /**
   *
   * Define states
   *
   */
  const [resetMsg, setResetMsg] = useState(false);
  const [activeFetch, setActiveFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [prefilter, setPreFilter] = useState("");
  const [applyForm, setApplyForm] = useState(false);
  const [formAction, setFormAction] = useState("");

  const initForm = {
    id: 0,
    name: "",
    description: "",
    date: "",
    url: "",
    state: 0,
    talkImagen: "",
    talkImagen64: "",
  };
  const [form, setForm] = useState(initForm);

  /**
   *
   * Efecto para llamar al listado al momento de montar el componente
   *
   */
  useEffect(() => {
    async function initData() {
      //call reset state of data
      await props.dataActions.resetData();
      await props.talkActions.fetchDataAction(true, true, "", 1);
    }
    initData();
  }, [props.talkActions, props.dataActions]);

  /**
   *
   * Efecto para validar la actualizacion del componente y llamar al listado
   *
   */
  useEffect(() => {
    async function fetchData() {
      await props.talkActions.fetchDataAction(resetMsg, false, filter, page);
    }
    if (activeFetch) {
      fetchData();
      setActiveFetch(false);
      setResetMsg(false);
    }
  }, [activeFetch, filter, page, resetMsg, props.talkActions]);

  /**
   *
   * Efecto para validar el buscar por id para actualizar registro
   *
   */
  useEffect(() => {
    if (props.dataId.get("Id") > 0) {
      setForm({
        ...form,
        id: props.dataId.get("Id"),
        name: props.dataId.get("Nombre"),
        description: props.dataId.get("Descripcion"),
        date: props.dataId.get("Fecha"),
        url: props.dataId.get("Url"),
        state: props.dataId.get("EstadoCharlaId"),
        talkImagen64: props.dataId.get("ImagenCharla64"),
      });
      //call reset id
      props.dataActions.resetFetchIdData();
    }
  }, [props.dataId, props.dataActions, form]);

  /**
   *
   * Efecto para validar errores en caso de abrir modal
   *
   */
  useEffect(() => {
    if (props.modalIsOpen && props.errorMsg) {
      props.utilActions.closeModal();
    }
  }, [props.modalIsOpen, props.errorMsg, props.utilActions]);

  /**
   * Evento de cambio en el filtro
   *
   * @param event
   * @author aquiros
   */
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  /**
   * Evento de aplicar filtro de busqueda
   *
   * @param event
   * @author lferrer
   */
  const handleApplyFilter = () => {
    if (filter !== prefilter) {
      setPreFilter(filter);
      setResetMsg(true);
      setPage(1);
      setActiveFetch(true);
    }
  };

  /**
   * Paginacion de registros
   *
   * @param pageNumber
   * @author aquiros
   */
  const pagination = async (pageNumber) => {
    if (pageNumber !== page) {
      setResetMsg(true);
      setPage(pageNumber);
      setActiveFetch(true);
    }
  };

  /**
   * Evento de submit
   *
   * @param data
   * @author aquiros
   */
  const onSubmit = async (data) => {
    if (data) {
      data = { ...data, talkImagen64: form.talkImagen64 };
    }
    if (formAction === "add") {
      await props.talkActions.saveAction(data);
    }

    if (formAction === "update") {
      await props.talkActions.updateAction(form.id, data);
    }

    if (props.modalType === "delete") {
      await props.talkActions.deleteAction(props.objectSelect.get("Id"));
    }

    if (formAction !== "") {
      setForm(initForm);
      setApplyForm(false);
      setFormAction("");
    }

    //reset page and call fetch data
    setPage(1);
    setActiveFetch(true);
  };

  /**
   * Evento de apertura de modal
   *
   * @param event
   * @author aquiros
   */
  const handleOpenModal = async (event) => {
    event.preventDefault();
    const modalAction = event.currentTarget.name || event.currentTarget.id;
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    //reset msg
    await props.utilActions.resetAllMsg();

    if (modalAction === "delete" || modalAction === "members") {
      object = props.data
        .get("Resultado")
        .find((p) => p.get("Id") === parseInt(id));
    }
    //open modal
    await props.utilActions.openModal(modalAction, object);
  };

  /**
   * Evento de cierre de modal
   *
   * @param event
   * @author lferrer
   */
  const handleCancelModal = () => {
    props.utilActions.closeModal();
  };

  /**
   * Evento de apertura de formulario
   *
   * @author lferrer
   */
  const handleOpenForm = async (event) => {
    event.preventDefault();
    const formAction = event.currentTarget.name || event.currentTarget.id;
    const id = event.currentTarget.getAttribute("data-id");

    //reset msg
    await props.utilActions.resetAllMsg();

    if (formAction === "update") {
      await props.talkActions.fetchByIDDataAction(id);
    }

    setFormAction(formAction);
    setApplyForm(true);
  };

  /**
   * Evento de cierre de formulario
   *
   * @author lferrer
   */
  const handleCloseForm = () => {
    setForm(initForm);
    setApplyForm(false);
    setFormAction("");
    setActiveFetch(true);
  };

  /**
   * Validar estado de charla
   *
   * @param id
   * @author lferrer
   */
  const validatetalkstate = (id) => {
    let returnTalkState = properties.com_feria_label_talk_undefined;
    if (
      id &&
      props.childDataKeys &&
      props.childDataKeys.get("talkstatus") &&
      props.childDataKeys.get("talkstatus").get("Resultado")
    ) {
      const talkState = props.childDataKeys
        .get("talkstatus")
        .get("Resultado")
        .find((r) => parseInt(r.get("Id")) === parseInt(id));
      if (talkState) {
        returnTalkState = talkState.get("Codigo");
      }
    }
    return returnTalkState;
  };

  /**
   * Validacion de titulo del card
   *
   * @author lferrer
   */
  const validateCardTitle = () => {
    let title = properties.com_feria_label_talk_card_title;
    if (applyForm) {
      if (formAction === "add") {
        title = properties.com_feria_label_talk_add_title;
      } else {
        title = properties.com_feria_label_talk_update_title;
      }
    }
    return title;
  };

  return (
    <>
      {props.isLoading && (
        <Loading title={properties.com_feria_label_loading_title} />
      )}
      <NavBar nav={properties.com_feria_label_talk_card_title} />
      {props.errorMsg && (
        <MessageManager
          type={constant.DANGER_MSG}
          msg={props.errorMsg}
          details=""
          onConfirm={props.utilActions.resetAllMsg}
        />
      )}
      {props.successMsg && (
        <MessageManager
          type={constant.SUCCESS_MSG}
          msg={props.successMsg}
          details=""
          onConfirm={props.utilActions.resetAllMsg}
        />
      )}
      <div className="card mb-4">
        <div className="card-header">
          <div className="row">
            <div className="col-6 pt-2 bold-text-test">
              <i className="fas fa-table mr-1"></i>
              {validateCardTitle()}
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-primary"
                onClick={handleOpenForm}
                name="add"
              >
                <i className="fas fa-plus" />{" "}
                {properties.com_feria_label_button_add_title}
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          {!applyForm && (
            <>
              <div className="col-12">
                {props.data &&
                  props.data.get("Resultado") &&
                  props.data.get("Resultado").size > 0 && (
                    <>
                      <CustomPagination
                        activePage={page}
                        itemsCountPerPage={props.data.get("RegistroXPagina")}
                        totalItemsCount={props.data.get("TotalRegistro") || 0}
                        pageRangeDisplayed={5}
                        innerClass="pagination justify-content-lg-end justify-content-md-end justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        callback={pagination.bind(this)}
                      />
                    </>
                  )}
              </div>
              <div className="table-responsive pt-3 col-lg-10 m-auto">
                <table
                  className="table table-bordered table-hover"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th colSpan="6">
                        <div className="input-group justify-content-lg-end">
                          <input
                            type="text"
                            className="form-control col-4"
                            name="filter"
                            id="filter"
                            value={filter}
                            onChange={handleFilterChange}
                            title={
                              properties.com_feria_label_table_filter_placeholder
                            }
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              onClick={handleApplyFilter}
                            >
                              {properties.com_feria_label_button_search_title}
                            </button>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>{properties.com_feria_label_talk_name_title}</th>
                      <th>
                        {properties.com_feria_label_talk_description_title}
                      </th>
                      <th>{properties.com_feria_label_talk_date_title}</th>
                      <th>{properties.com_feria_label_talk_url_title}</th>
                      <th>{properties.com_feria_label_talk_state_title}</th>
                      <th className="text-center">
                        {properties.com_feria_label_table_action}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.data &&
                      props.data.get("Resultado") &&
                      props.data.get("Resultado").size > 0 &&
                      props.data.get("Resultado").map((p, i) => (
                        <tr key={p.get("Id")}>
                          <td>{p.get("Nombre")}</td>
                          <td>{p.get("Descripcion")}</td>
                          <td>{p.get("Fecha")}</td>
                          <td>{p.get("Url")}</td>
                          <td>{validatetalkstate(p.get("EstadoCharlaId"))}</td>
                          <td className="text-center">
                            <a
                              href="# "
                              className="text-decoration-none text-reset"
                              data-toggle="tooltip"
                              data-placement="top"
                              name="update"
                              data-id={p.get("Id")}
                              title={
                                properties.com_feria_label_button_update_title
                              }
                              onClick={handleOpenForm}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                            <a
                              href="# "
                              className="text-decoration-none text-reset pl-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              name="members"
                              data-id={p.get("Id")}
                              title={
                                properties.com_feria_label_talkmember_exhibitors
                              }
                              onClick={handleOpenModal}
                            >
                              <i className="fas fa-user-friends"></i>
                            </a>
                            <a
                              href="# "
                              className="text-decoration-none text-reset pl-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              name="delete"
                              data-id={p.get("Id")}
                              title={
                                properties.com_feria_label_button_delete_title
                              }
                              onClick={handleOpenModal}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    {(!props.data ||
                      !props.data.get("Resultado") ||
                      props.data.get("Resultado").size <= 0) && (
                      <tr>
                        <td colSpan="4">
                          {properties.com_feria_label_table_empty}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {applyForm && (formAction === "add" || formAction === "update") && (
            <AddOrUpdateTalkForm
              formType={formAction}
              form={form}
              setForm={setForm}
              talkstatus={props.childDataKeys.get("talkstatus")}
              handleOpenModal={handleOpenModal.bind(this)}
              onSubmit={onSubmit.bind(this)}
              handleCloseForm={handleCloseForm.bind(this)}
            />
          )}
        </div>
      </div>

      {props.modalType === "members" && (
        <TalkMemberModal
          isOpen={props.modalIsOpen}
          modalSize="lg"
          talkId={props.objectSelect.get("Id")}
          handleCancelModal={handleCancelModal.bind(this)}
        />
      )}

      {props.modalType === "delete" && (
        <DeleteModal
          isOpen={props.modalIsOpen}
          data={props.objectSelect.get("Identificacion")}
          modalSize=""
          handleModal={onSubmit.bind(this)}
          handleCancelModal={handleCancelModal.bind(this)}
        />
      )}

      {props.modalType === "previewimage" && (
        <ViewImagenModal
          isOpen={props.modalIsOpen}
          img={form.talkImagen64}
          modalSize="lg"
          handleCancelModal={handleCancelModal.bind(this)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    //page manager state
    isLoading: state.get("pageManager").get("loading"),
    isPageLoading: state.get("pageManager").get("pageLoading"),
    successMsg: state.get("pageManager").get("successMsg"),
    errorMsg: state.get("pageManager").get("errorMsg"),
    //data state
    data: state.get("data").get("data"),
    dataId: state.get("data").get("dataId"),
    childDataKeys: state.get("data").get("childDataKeys"),

    //modal states
    modalIsOpen: state.get("modal").get("isOpen"),
    modalType: state.get("modal").get("modalType"),
    objectSelect: state.get("modal").get("objectSelect"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    talkActions: bindActionCreators(talkActions, dispatch),
    utilActions: bindActionCreators(utilActions, dispatch),
    dataActions: bindActionCreators(dataActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Talk);

import React from "react";
import { properties } from "../../utils/Properties_es";
import Modal from "./CustomModal";

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente modal para ver las imagenes 
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const ViewImagenModal = (props) => (
  <Modal
    isOpen={props.isOpen}
    modalSize={props.modalSize}
    modalTitle={properties.com_feria_label_modal_viewimagen_title}
  >
    <div className="row justify-content-center">
      <div className="col-6 text-center">
        <img src={`data:image;base64,${props.img}`} alt="imagen" className="w-100"/> 
      </div>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        id="cancelModal"
        onClick={props.handleCancelModal}
      >
        {properties.com_feria_label_button_close_title}
      </button>
    </div>
  </Modal>
);

export default ViewImagenModal;

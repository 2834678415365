import { urls } from "../../../utils/UrlConstant";
import { privateFetch } from "../../../utils/PrivateFetch";
import { properties } from "../../../utils/Properties_es";
import qs from "qs";
import { validateResponseError } from "../../others/ValidateServices";

export const SVFindRequest = async (
  filter,
  page,
  allRecords,
  customPageFair
) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["filtro", filter && filter !== "" ? filter : ""],
    ["pagina", page],
    [
      "registroXPagina",
      allRecords
        ? 0
        : customPageFair
        ? process.env.REACT_APP_FERIA_TALKS_REGISTRATION_X_PAGE
        : process.env.REACT_APP_REGISTRATION_X_PAGE,
    ],
  ]);
  await privateFetch
    .get(urls.REQUEST_TALK_LIST, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talk_find_error,
        false
      );
    });
  return responseData;
};

export const SVFindByIdRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);

  await privateFetch
    .get(urls.REQUEST_TALK_ID, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talk_findid_error,
        false
      );
    });
  return responseData;
};

export const SVSaveRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    Id: data.id,
    Nombre: data.name,
    Descripcion: data.description,
    Fecha: data.date,
    Url: data.url,
    EstadoCharlaId: data.state,
    ImagenCharla64:data.talkImagen64,
  };
  await privateFetch
    .post(urls.REQUEST_TALK, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talk_save_error,
        false
      );
    });
  return responseData;
};

export const SVUpdateRequest = async (id, data) => {
  let responseData = {};
  const requestBody = {
    Id: id,
    Nombre: data.name,
    Descripcion: data.description,
    Fecha: data.date,
    Url: data.url,
    EstadoCharlaId: data.state,
    ImagenCharla64:data.talkImagen64,
  };
  await privateFetch
    .put(urls.REQUEST_TALK, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talk_update_error,
        false
      );
    });
  return responseData;
};

export const SVDeleteRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);
  await privateFetch
    .delete(urls.REQUEST_TALK, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talk_delete_error,
        false
      );
    });
  return responseData;
};

import * as utilActions from "./UtilActions";
import * as systemParamActions from "./boardActions/fairAdmin/SystemParamActions";
import * as dataActions from "./DataActions";
import { actionsType } from "./utils/ActionType";
import {
  SVloginRequest,
  SVRevalidateTokenRequest,
  SVlogoutRequest,
  SVloginReset
} from "../services/security/SecurityService";
import { SVFindAuthoritiesByRol } from "../services/boardServices/security/rol/SVRolRequest";
import { SVFindRequest } from "../services/boardServices/generalMaintenance/catalogs/SVSurveyOptionRequest";
import { SVSaveRequest } from "../services/boardServices/fairAdmin/SVSurveyRequest";
import {
  saveLogin,
  clearLogin,
  saveCurrentRol,
  clearCurrentRol,
  saveChangeRol,
} from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { properties } from "../utils/Properties_es";
export const loginAction = (data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call login
      const returnData = await SVloginRequest(data);

      //validar que el usuario tenga al menos un rol
      if (!returnData.Roles) {
        dispatch(
          utilActions.setErrorMsg(properties.com_feria_label_login_rol_error)
        );
      } else {
        //save user state
        await dispatch(successLogin(returnData));

        //save localStorage
        await saveLogin(returnData);

        //si el usuario solo tiene un solo rol se llama al proceso de validar
        if (returnData.Roles.length === 1) {
          await dispatch(applyRolAction(returnData.Roles[0], false, false));
        }
      }
      //set loading
      await dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const resetAction = (data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //loading
    dispatch(utilActions.isLoading(true));
    try {
      //call reset
      const returnData = await SVloginReset(data);
      //Mostrar mensaje
      if (returnData.Exitoso === 1) {
        dispatch(utilActions.setSuccessMsg(properties.com_feria_label_resetpass_success));
      }
      //set loading
      await dispatch(utilActions.isLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isLoading(false));
    }
  };
};

export const applyRolAction = (
  rol,
  isChange,
  applyLoading,
  applyPageLoading
) => {
  return async (dispatch) => {
    //loading
    if (applyLoading) {
      await dispatch(utilActions.isLoading(true));
    }

    if (applyPageLoading) {
      await dispatch(utilActions.isPageLoading(true));
    }

    try {
      //call login
      const returnData = await SVFindAuthoritiesByRol(rol.Id);

      //save current Rol
      await dispatch(applyCurrentRol(rol, returnData, isChange));

      //save localStorage
      await saveCurrentRol(rol, returnData);

      if (applyLoading) {
        await dispatch(utilActions.isLoading(false));
      }
      if (applyPageLoading) {
        await dispatch(utilActions.isPageLoading(false));
      }
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      if (applyLoading) {
        await dispatch(utilActions.isLoading(false));
      }
      if (applyPageLoading) {
        await dispatch(utilActions.isPageLoading(false));
      }
    }
  };
};

export const applyChangeRolAction = (
  rol,
  roles,
) => {
  return async (dispatch) => {
    try {
      //call authorities
      const returnData = await SVFindAuthoritiesByRol(rol.Id);

      //save current Rol
      await dispatch(changeRolesUser(rol, roles, returnData));

      //save localStorage
      await saveChangeRol(rol,roles,returnData);

    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
    }
  };
};

export const revalidateTokenAction = (data) => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //close modal sesion
    await dispatch(utilActions.closeModal());

    //loading
    dispatch(utilActions.isPageLoading(true));
    try {
      //call login
      const returnData = await SVRevalidateTokenRequest(data);
      //validar que el usuario tenga al menos un rol
      if (!returnData) {
        dispatch(
          utilActions.setErrorMsg(
            properties.com_feria_label_login_revalidatetoken_error
          )
        );
      } else {
        //save localStorage
        await saveLogin(returnData);
      }
      //set loading
      await dispatch(utilActions.isPageLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isPageLoading(false));
    }
  };
};

export const surveyOptionAction = () => {
  return async (dispatch) => {
    //reset msg
    dispatch(utilActions.resetAllMsg());

    //loading
    dispatch(utilActions.isPageLoading(true));
    try {
      //call request
      const returnData = await SVFindRequest("", 0, true);
      //save data state
      await dispatch(dataActions.fetchSurveyData(returnData));
      //set loading
      await dispatch(utilActions.isPageLoading(false));
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isPageLoading(false));
    }
  };
};

export const logoutAction = (data, bitacoraData) => {
  return async (dispatch) => {
    
    try {
      await dispatch(utilActions.isPageLoading(true));
      if (data) {
        //close modal
        dispatch(utilActions.closeModal());
        //llamar al servicio de encuesta
        const surveyResp = await SVSaveRequest(data);
        if (surveyResp.Exitoso === 1) {
          dispatch(
            utilActions.setSuccessMsg(
              properties.com_feria_label_survey_save_success
            )
          );
        } else {
          dispatch(
            utilActions.setErrorMsg(
              properties.com_feria_label_survey_save_error
            )
          );
        }
      }

      if(bitacoraData){
        //llamar al servicio de bitacora para el logout
        try {
          await SVlogoutRequest(bitacoraData);
        } catch (error) {
          console.error(error.message);
        }
      }

      //clear localstore
      clearLogin();
      clearCurrentRol();

      //llamar a LOGOUT
      await dispatch({ type: actionsType.LOGOUT });
       
      //close pageloading
      await dispatch(utilActions.isPageLoading(false));
      
      
       //devolver los parametros del sistema
      await dispatch(systemParamActions.systemParamAction());
    } catch (error) {
      dispatch(utilActions.setErrorMsg(error.message));
      dispatch(utilActions.isPageLoading(false));
    }
  };
};

export const successLogin = (data) => {
  const decodeToken = jwt_decode(data.Token);
  return {
    type: actionsType.LOGIN,
    payload: {
      userInfo: {
        identification: decodeToken.nameid,
        userCode: data.UserInfo.CodUsuario,
        fullName: data.UserInfo.NombreCompleto,
        correo: data.UserInfo.Correo,
        bitacoraId: data.UserInfo.BitacoraId,
        roles: data.Roles,
      },
    },
  };
};

export const applyCurrentRol = (rol, authoritiesData, isChange) => {
  return {
    type: isChange ? actionsType.CHANGEROL : actionsType.APPLYROL,
    payload: {
      rol,
      authorities: authoritiesData.Data
        ? authoritiesData.Data.map((a) => ({ Codigo: a.Codigo, Url: a.Url }))
        : null,
    },
  };
};

export const changeRolesUser = (rol,roles,authoritiesData) => {
  return {
    type: actionsType.CHANGEALLROL,
    payload: {
      rol,
      roles,
      authorities: authoritiesData.Data,
    },
  };
};

export const securityReload = (data) => {
  const decodeToken = jwt_decode(data.Token);
  return async (dispatch) => {
    dispatch({
      type: actionsType.SEGURITYRELOAD,
      payload: {
        userInfo: {
          identification: decodeToken.nameid,
          userCode: data.UserInfo.CodUsuario,
          fullName: data.UserInfo.NombreCompleto,
          correo: data.UserInfo.Correo,
          bitacoraId: data.UserInfo.BitacoraId,
          roles: data.Roles,
        },
        rol: data.CurrentRol,
        authorities: data.CurrentAuthorities
          ? data.CurrentAuthorities.map((a) => ({
              Codigo: a.Codigo,
              Url: a.Url,
            }))
          : null,
      },
    });
  };
};

export const actionsType = {
  /** general */
  IS_LOADING: "IS_LOADING",
  IS_PAGE_LOADING: "IS_PAGE_LOADING",
  SUCCESSMSG: "SUCCESSMSG",
  ERRORMSG: "ERRORMSG",
  RESETMSG: "RESETMSG",
  CHILDSUCCESSMSG: "CHILDSUCCESSMSG",
  CHILDERRORMSG: "CHILDERRORMSG",
  CHILDRESETMSG: "CHILDRESETMSG",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  /** security */
  LOGIN: "LOGIN",
  REVALIDATELOGIN: "REVALIDATELOGIN",
  APPLYROL: "APPLYROL",
  CHANGEROL: "CHANGEROL",
  CHANGEALLROL: "CHANGEALLROL",
  LOGOUT: "LOGOUT",
  LOADSYSTEMPARAM: "LOADSYSTEMPARAM",
  SEGURITYRELOAD: "SEGURITYRELOAD",

  /** DATA ACTIONS */
  FETCH_DATA: "FETCH_DATA",
  FETCH_DATA_CHILD_KEYS: "FETCH_DATA_CHILD_KEYS",
  FETCH_DATA_CHILD: "FETCH_DATA_CHILD",
  FETCH_DATA_CHILD2: "FETCH_DATA_CHILD2",
  FETCH_DATA_CHILD3: "FETCH_DATA_CHILD3",
  FETCH_DATA_CHILD4: "FETCH_DATA_CHILD4",
  FETCH_DATA_CHILD5: "FETCH_DATA_CHILD5",
  FETCHID_DATA: "FETCHID_DATA",
  FETCHID_DATACHILD: "FETCHID_DATACHILD",
  FETCH_DATA_SURVEY: "FETCH_DATA_SURVEY",
  FETCH_DATA_USERSURVEY: "FETCH_DATA_USERSURVEY",
  FETCH_DATA_TALK: "FETCH_DATA_TALK",
  SAVE_DATA: "SAVE_DATA",
  UPDATE_DATA: "UPDATE_DATA",
  DELETE_DATA: "DELETE_DATA",
  RESET_DATA: "RESET_DATA",
  RESET_CHILD_DATA: "RESET_CHILD_DATA",
};

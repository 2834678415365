import React from "react";
import { Link } from "react-router-dom";

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente para manejo de barra de navegacion en panel admin
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const NavBar = (props) => (
  <>
    
    <ol className="breadcrumb pt-4 pb-2">
      <li className="breadcrumb-item active"><Link to="/admin">Inicio</Link></li>
      {props.nav && (
          <li className="breadcrumb-item active">{props.nav}</li>
      )}
    </ol>
  </>
);

export default NavBar;

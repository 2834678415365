import { properties } from "../../utils/Properties_es";

export const validateResponseError = (
  error,
  message,
  default400,
  apply400ServerError
) => {
  if (error.response) {
    let response = error.response;
    switch (response.status) {
      case 401:
        throw new Error(properties.com_feria_label_request_unauthorized);
      case 400:
        throw new Error(
          default400
            ? properties.com_feria_label_request_badrequest
            : apply400ServerError
            ? response.data && response.data.Mensaje
              ? response.data.Mensaje
              : message
            : message
        );
      case 500:
        throw new Error(message);
      default:
        throw new Error(message);
    }
  } else {
    //network connection
    throw new Error(properties.com_feria_label_request_connection);
  }
};

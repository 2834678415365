import { fromJS } from "immutable";
import { Map as map } from "immutable";
import { actionsType } from "./utils/ActionType";

export const fetchData = (responseData) => {
  return {
    type: actionsType.FETCH_DATA,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchDataChildKeys = (data) => {
  return {
    type: actionsType.FETCH_DATA_CHILD_KEYS,
    payload: {
      data: fromJS(data),
    },
  };
};

export const fetchDataChild = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_CHILD,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchDataChild2 = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_CHILD2,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchDataChild3 = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_CHILD3,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchDataChild4 = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_CHILD4,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchDataChild5 = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_CHILD5,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchIdData = (responseData) => {
  return {
    type: actionsType.FETCHID_DATA,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchIdChildData = (responseData) => {
  return {
    type: actionsType.FETCHID_DATACHILD,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchSurveyData = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_SURVEY,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchUserSurveyData = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_USERSURVEY,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

export const fetchTalkData = (responseData) => {
  return {
    type: actionsType.FETCH_DATA_TALK,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};


export const saveData = (responseData) => {
  return {
    type: actionsType.SAVE_DATA,
    payload: {
      exitoso: responseData.Exitoso,
    },
  };
};

export const updateData = (responseData) => {
  return {
    type: actionsType.UPDATE_DATA,
    payload: {
      exitoso: responseData.Exitoso,
    },
  };
};

export const deleteData = (responseData) => {
  return {
    type: actionsType.DELETE_DATA,
    payload: {
      exitoso: responseData.Exitoso,
    },
  };
};

export const resetFetchIdData = () => {
  return {
    type: actionsType.FETCHID_DATA,
    payload: {
      data: map(),
    },
  };
};

export const resetFetchIdChildData = () => {
  return {
    type: actionsType.FETCHID_DATACHILD,
    payload: {
      data: map(),
    },
  };
};

export const resetChildData = () => {
  return {
    type: actionsType.RESET_CHILD_DATA,
  };
};

export const resetData = () => {
  return {
    type: actionsType.RESET_DATA,
  };
};

import { fromJS } from "immutable";
import { actionsType } from "./utils/ActionType";

export function isLoading(value) {
  return {
    type: actionsType.IS_LOADING,
    payload: {
      value,
    },
  };
}

export function isPageLoading(value) {
  return {
    type: actionsType.IS_PAGE_LOADING,
    payload: {
      value,
    },
  };
}

export function dynamicLoading(value,isDefaultloading) {
  return {
    type: isDefaultloading ? actionsType.IS_LOADING: actionsType.IS_PAGE_LOADING,
    payload: {
      value,
    },
  };
}

export const setSuccessMsg = (value) => {
  return {
    type: actionsType.SUCCESSMSG,
    payload: {
      value,
    },
  };
};

export const setErrorMsg = (value) => {
  return {
    type: actionsType.ERRORMSG,
    payload: {
      value,
    },
  };
};

export const resetAllMsg = () => {
  return {
    type: actionsType.RESETMSG,
  };
};

export const setChildSuccessMsg = (value) => {
  return {
    type: actionsType.CHILDSUCCESSMSG,
    payload: {
      value,
    },
  };
};

export const setChildErrorMsg = (value) => {
  return {
    type: actionsType.CHILDERRORMSG,
    payload: {
      value,
    },
  };
};

export const resetAllChildMsg = () => {
  return {
    type: actionsType.CHILDRESETMSG,
  };
};

export const dynamicResponseMsg = (exitoso, successMsg, errorMsg) => {
  return {
    type: exitoso === 1 ? actionsType.SUCCESSMSG : actionsType.ERRORMSG,
    payload: {
      value: exitoso === 1 ? successMsg : errorMsg,
    },
  };
};

export const openModal = (modalType, objectSelect) => {
  return {
    type: actionsType.OPEN_MODAL,
    payload: {
      modalType,
      objectSelect,
    },
  };
};

export const closeModal = () => {
  return {
    type: actionsType.CLOSE_MODAL,
  };
};

export const setSystemParam = (responseData) => {
  return {
    type: actionsType.LOADSYSTEMPARAM,
    payload: {
      data: fromJS(responseData.Data),
    },
  };
};

import { fromJS } from "immutable";
import { actionsType } from "../actions/utils/ActionType";

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description Reducer para manejo de modales en la aplicacion 
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const initialState = fromJS({
  isOpen: false,
  modalType: "",
  objectSelect: null,
});

function modal(state = initialState, action) {
  switch (action.type) {
    case actionsType.OPEN_MODAL:
      return state.merge({
        isOpen: true,
        modalType: action.payload.modalType,
        objectSelect: fromJS(action.payload.objectSelect),
      });
    case actionsType.CLOSE_MODAL:
      return state = initialState;
    default:
      return state;
  }
}

export default modal;

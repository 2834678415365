import { urls } from "../../../utils/UrlConstant";
import { privateFetch } from "../../../utils/PrivateFetch";
import { publicFetch } from "../../../utils/PublicFetch";
import { properties } from "../../../utils/Properties_es";
import qs from "qs";
import { validateResponseError } from "../../others/ValidateServices";

export const SVFindByIdRequest = async (id, applyPublicFetch) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);

  if (applyPublicFetch) {
    await publicFetch
      .get(urls.REQUEST_SYSTEMPARAM_ID, {
        params,
      })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        validateResponseError(
          error,
          properties.com_feria_label_systemparam_findid_error,
          false
        );
      });
  } else {
    await privateFetch
      .get(urls.REQUEST_SYSTEMPARAM_ID, {
        params,
      })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch(async (error) => {
        validateResponseError(
          error,
          properties.com_feria_label_systemparam_findid_error,
          false
        );
      });
  }

  return responseData;
};

export const SVSaveRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    ActivarFeria: data.activeFair,
    ActivarInscripcion: data.activeRegistration,
  };
  await privateFetch
    .post(urls.REQUEST_SYSTEMPARAM, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_systemparam_save_error,
        false
      );
    });
  return responseData;
};

export const SVUpdateRequest = async (id, data) => {
  let responseData = {};
  const requestBody = {
    Id: id,
    ActivarFeria: data.activeFair ? data.activeFair : "N",
    ActivarInscripcion: data.activeRegistration ? data.activeRegistration : "N",
  };
  await privateFetch
    .put(urls.REQUEST_SYSTEMPARAM, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_systemparam_update_error,
        false
      );
    });
  return responseData;
};

export const SVDeleteRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);
  await privateFetch
    .delete(urls.REQUEST_SYSTEMPARAM, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_systemparam_delete_error,
        false
      );
    });
  return responseData;
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore,applyMiddleware} from 'redux';
import App from './routes/App';
import reducer from './reducers/index';
import {Map as map} from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
//import logger from 'redux-logger';
import thunk from 'redux-thunk';

//styles
import 'bootstrap/dist/css/bootstrap.css';

//create store of app
const store = createStore(
  reducer,
  map(),
  composeWithDevTools(
    applyMiddleware(
      /*logger,*/thunk
    ),
  ));

ReactDOM.render(
  //define provider
  <Provider store={store}>
    <App />
  </Provider>
  ,document.getElementById('app'),
);

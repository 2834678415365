import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as talkMemberActions from "../../../../../actions/boardActions/fairAdmin/TalkMemberActions";
import * as utilActions from "../../../../../actions/UtilActions";
import * as dataActions from "../../../../../actions/DataActions";
import { bindActionCreators } from "redux";
import CustomPagination from "../../../../../components/utils/CustomPagination";
import { properties } from "../../../../../utils/Properties_es";
import MessageManager from "../../../../../components/utils/MessageManager";
import { constant } from "../../../../../utils/Constant";
import Modal from "../../../../../components/utils/CustomModal";
import { useForm } from "react-hook-form";
import { validateFieldStyle } from "../../../../../utils/UtilsFunction";
/****************************************************
 * REVISION 1.0
 * Fecha: 19/11/2020
 *
 * @description Pantalla de integrantes por talk
 * @author lferrer
 * @version 1.0
 *
 ****************************************************/
const TalkMemberModal = (props) => {
  /**
   *
   * Define states
   *
   */
  const [resetMsg, setResetMsg] = useState(false);
  const [activeFetch, setActiveFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [applyForm, setApplyForm] = useState(false);
  const [formAction, setFormAction] = useState("");

  /**
   *
   * Form states
   *
   */
  const initForm = {
    id: 0,
    talkId: props.talkId,
    fullname: "",
    academicdegree: "",
    profession: "",
  };


  const [applyStyleForm, setApplyStyleForm] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: initForm,
    mode: "onChange",
  });

  /**
   *
   * Efecto para llamar al listado al momento de montar el componente
   *
   */
  useEffect(() => {
    async function initData() {
      //call reset state of data
      await props.dataActions.resetChildData();
      await props.talkMemberActions.fetchDataAction(
        true,
        true,
        props.talkId,
        1
      );
    }
    initData();
  }, [props.talkId, props.talkMemberActions, props.dataActions]);

  /**
   *
   * Efecto para validar la actualizacion del componente y llamar al listado
   *
   */
  useEffect(() => {
    async function fetchData() {
      await props.talkMemberActions.fetchDataAction(
        resetMsg,
        false,
        props.talkId,
        page
      );
    }
    if (activeFetch) {
      fetchData();
      setActiveFetch(false);
      setResetMsg(false);
    }
  }, [activeFetch, props.talkId, page, resetMsg, props.talkMemberActions]);

  /**
   *
   * Efecto para validar el buscar por id para actualizar registro
   *
   */
  useEffect(() => {
    if (props.chilDataId.get("Id") > 0) {
      reset({
        ...initForm,
        id: props.chilDataId.get("Id"),
        talkId: props.chilDataId.get("CharlaId"),
        fullname: props.chilDataId.get("NombreCompleto"),
        academicdegree: props.chilDataId.get("GradoAcademico"),
        profession: props.chilDataId.get("TituloPuesto")
      });

      //call reset id
      props.dataActions.resetFetchIdChildData();
    }
  }, [props.chilDataId, props.dataActions, initForm, reset]);

  /**
   *
   * Efecto para validar errores en caso de abrir formulario
   *
   */
  useEffect(() => {
    if (applyForm && props.errorMsg) {
      reset(initForm);
      setApplyForm(false);
      setFormAction("");
      setActiveFetch(true);
      setApplyStyleForm(false);
    }
  }, [initForm, applyForm, props.errorMsg, reset]);

  /**
   * Paginacion de registros
   *
   * @param pageNumber
   * @author lferrer
   */
  const pagination = async (pageNumber) => {
    if (pageNumber !== page) {
      setResetMsg(true);
      setPage(pageNumber);
      setActiveFetch(true);
    }
  };

  /**
   * Evento de apertura de formulario
   *
   * @author lferrer
   */
  const handleOpenForm = async (event) => {
    event.preventDefault();
    const formAction = event.currentTarget.name || event.currentTarget.id;
    const id = event.currentTarget.getAttribute("data-id");
    //reset msg
    await props.utilActions.resetAllChildMsg();

    if (formAction === "update") {
      await props.talkMemberActions.fetchByIDDataAction(id);
    }

    setFormAction(formAction);
    setApplyForm(true);
  };

  /**
   * Evento de cierre de formulario
   *
   * @author lferrer
   */
  const handleCloseForm = () => {
    reset(initForm);
    setApplyForm(false);
    setFormAction("");
    setActiveFetch(true);
    setPage(1);
    setApplyStyleForm(false);
  };

  /**
   * Evento de cambio de estilo en el formulario
   *
   * @author lferrer
   */
  const applyStyleToForm = () => {
    if (!applyStyleForm) {
      setApplyStyleForm(true);
    }
  };

  /**
   * Evento de submit
   *
   * @param data
   * @author lferrer
   */
  const onSubmit = async (data) => {
    if (formAction === "add") {
      await props.talkMemberActions.saveAction(data);
    }
    if (formAction === "update") {
      await props.talkMemberActions.updateAction(data.id, data);
    }
    //reset page and call fetch data
    handleCloseForm();
  };

  /**
   * Evento de eliminar registro
   *
   * @param e
   * @author lferrer
   */
  const onDelete = async (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    await props.talkMemberActions.deleteAction(id);
    //reset page and call fetch data
    setPage(1);
    setActiveFetch(true);
  };

  /**
   * Validacion de titulo del card
   *
   * @author lferrer
   */
  const validateModalTitle = () => {
    let title = properties.com_feria_label_talkmember_card_title;
    if (applyForm) {
      if (formAction === "add") {
        title = properties.com_feria_label_talkmember_add_title;
      } else {
        title = properties.com_feria_label_talkmember_update_title;
      }
    }
    return title;
  };

  /**
   * Validacion de boton de cancelar
   *
   * @author lferrer
   */
  const validateCancelButton = () => {
    if (applyForm) {
      handleCloseForm();
    } else {
      props.handleCancelModal();
    }
  };

  return (
    <>
      {props.childErrorMsg && (
        <MessageManager
          type={constant.DANGER_MSG}
          msg={props.childErrorMsg}
          details=""
          onConfirm={props.utilActions.resetAllChildMsg}
        />
      )}
      {props.childSuccessMsg && (
        <MessageManager
          type={constant.SUCCESS_MSG}
          msg={props.childSuccessMsg}
          details=""
          onConfirm={props.utilActions.resetAllChildMsg}
        />
      )}
      <Modal
        isOpen={props.isOpen}
        modalSize={props.modalSize}
        modalTitle={validateModalTitle()}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {!applyForm && (
            <>
              <div className="col-12 pb-1">
                <div className="row justify-content-end">
                  <div className="col-6 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={handleOpenForm}
                      name="add"
                      type="button"
                    >
                      <i className="fas fa-plus" />{" "}
                      {properties.com_feria_label_button_add_title}
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive pt-3 col-lg-12 m-auto">
                <table
                  className="table table-bordered table-hover"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>{properties.com_feria_label_user_fullname}</th>
                      <th>{properties.com_feria_label_talkmember_academicdegree}</th>
                      <th>{properties.com_feria_label_talkmember_profession}</th>
                      <th className="text-center">
                        {properties.com_feria_label_table_action}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.childData &&
                      props.childData.get("Resultado") &&
                      props.childData.get("Resultado").size > 0 &&
                      props.childData.get("Resultado").map((p) => (
                        <tr key={p.get("Id")}>
                          <td>{p.get("NombreCompleto")}</td>
                          <td>{p.get("GradoAcademico")}</td>
                          <td>{p.get("TituloPuesto")}</td>
                          <td className="text-center">
                            <a
                              href="# "
                              className="text-decoration-none text-reset"
                              data-toggle="tooltip"
                              data-placement="top"
                              name="update"
                              data-id={p.get("Id")}
                              title={
                                properties.com_feria_label_button_update_title
                              }
                              onClick={handleOpenForm}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                            <a
                              href="# "
                              className="text-decoration-none text-reset pl-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              name="delete"
                              data-id={p.get("Id")}
                              title={
                                properties.com_feria_label_button_delete_title
                              }
                              onClick={onDelete}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    {(!props.childData ||
                      !props.childData.get("Resultado") ||
                      props.childData.get("Resultado").size <= 0) && (
                      <tr>
                        <td colSpan="4">
                          {properties.com_feria_label_table_empty}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-12">
                {props.childData &&
                  props.childData.get("Resultado") &&
                  props.childData.get("Resultado").size > 0 && (
                    <>
                      <CustomPagination
                        activePage={page}
                        itemsCountPerPage={props.childData.get(
                          "RegistroXPagina"
                        )}
                        totalItemsCount={
                          props.childData.get("TotalRegistro") || 0
                        }
                        pageRangeDisplayed={5}
                        innerClass="pagination justify-content-lg-end justify-content-md-end justify-content-center"
                        itemClass="page-item"
                        linkClass="page-link"
                        callback={pagination.bind(this)}
                      />
                    </>
                  )}
              </div>
            </>
          )}
          {applyForm && (formAction === "add" || formAction === "update") && (
            <>
              <div className="form-group">
                <label htmlFor="fullname">
                  {properties.com_feria_label_user_fullname}
                </label>
                <input
                  className={`form-control input-lg ${validateFieldStyle(
                    applyStyleForm,
                    errors.fullname
                  )}`}
                  type="text"
                  name="fullname"
                  id="fullname"
                  maxLength={100}
                  ref={register({
                    required: true,
                    maxLength: 100,
                  })}
                />
                {errors.fullname && (
                  <span className="invalid-feedback">
                    {properties.com_feria_label_field_required}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="academicdegree">
                  {properties.com_feria_label_talkmember_academicdegree}
                </label>
                <input
                  className={`form-control input-lg ${validateFieldStyle(
                    applyStyleForm,
                    errors.academicdegree
                  )}`}
                  type="text"
                  name="academicdegree"
                  id="academicdegree"
                  maxLength={50}
                  ref={register({
                    required: true,
                    maxLength: 50,
                  })}
                />
                {errors.academicdegree && (
                  <span className="invalid-feedback">
                    {properties.com_feria_label_field_required}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="profession">
                  {properties.com_feria_label_talkmember_profession}
                </label>
                <input
                  className={`form-control input-lg ${validateFieldStyle(
                    applyStyleForm,
                    errors.profession
                  )}`}
                  type="text"
                  name="profession"
                  id="profession"
                  maxLength={100}
                  ref={register({
                    required: true,
                    maxLength: 100,
                  })}
                />
                {errors.profession && (
                  <span className="invalid-feedback">
                    {properties.com_feria_label_field_required}
                  </span>
                )}
              </div>
              <input name="id" type="hidden" ref={register} />
              <input name="talkId" type="hidden" ref={register} />
            </>
          )}
          <div className="modal-footer content-justify-end">
            {applyForm && (formAction === "add" || formAction === "update") && (
              <button
                className="btn btn-success"
                id="acceptModal"
                onClick={applyStyleToForm}
              >
                {formAction === "add"
                  ? properties.com_feria_label_button_save_title
                  : properties.com_feria_label_button_update_title}
              </button>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              id="cancelModal"
              onClick={validateCancelButton}
            >
              {applyForm
                ? properties.com_feria_label_button_back_title
                : properties.com_feria_label_button_close_title}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    //page manager state
    isLoading: state.get("pageManager").get("loading"),
    childSuccessMsg: state.get("pageManager").get("childSuccessMsg"),
    childErrorMsg: state.get("pageManager").get("childErrorMsg"),
    //data state
    childData: state.get("data").get("childData"),
    childData2: state.get("data").get("childData2"),
    chilDataId: state.get("data").get("chilDataId"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    talkMemberActions: bindActionCreators(talkMemberActions, dispatch),
    utilActions: bindActionCreators(utilActions, dispatch),
    dataActions: bindActionCreators(dataActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TalkMemberModal);

import { urls } from "../../../utils/UrlConstant";
import { privateFetch } from "../../../utils/PrivateFetch";
import { properties } from "../../../utils/Properties_es";
import qs from "qs";
import { validateResponseError } from "../../others/ValidateServices";
export const SVFindRequest = async (filter, opcionId, page, allRecords) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["filtro", filter && filter > 0 ? filter : ""],
    ["opcionid", opcionId && opcionId > 0 ? opcionId : 0],
    ["pagina", page],
    [
      "registroXPagina",
      allRecords ? 0 : process.env.REACT_APP_REGISTRATION_X_PAGE,
    ],
  ]);
  await privateFetch
    .get(urls.REQUEST_SURVEY_LIST, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_survey_find_error,
        false
      );
    });
  return responseData;
};

export const SVFindByUserRequest = async (filter, opcionId , userid, page, allRecords) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["filtro", filter && filter > 0  ? filter : ""],
    ["opcionid", opcionId && opcionId > 0 ? opcionId : 0],
    ["usuarioid", userid && userid > 0 ? userid : 0],
    ["pagina", page],
    [
      "registroXPagina",
      allRecords ? 0 : process.env.REACT_APP_REGISTRATION_X_PAGE,
    ],
  ]);
  await privateFetch
    .get(urls.REQUEST_SURVEY_LIST2, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_survey_find2_error,
        false
      );
    });
  return responseData;
};

export const SVFindByIdRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);

  await privateFetch
    .get(urls.REQUEST_SURVEY_ID, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_survey_findid_error,
        false
      );
    });
  return responseData;
};

export const SVSaveRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    Puntuacion: data.score,
    Descripcion: data.description ? data.description : null,
    OpcionEncuestaId:data.surveyOptionId && data.surveyOptionId > 0 ? data.surveyOptionId : null,
    UsuarioId:data.userId && data.userId > 0 ? data.userId : null,
    FechaCreacion: new Date().toISOString(),
  };
  await privateFetch
    .post(urls.REQUEST_SURVEY, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_survey_save_error,
        false
      );
    });
  return responseData;
};

export const SVUpdateRequest = async (id, data) => {
  let responseData = {};
  const requestBody = {
    Id: id,
    Puntuacion: data.score,
    Descripcion: data.description,
    OpcionEncuestaId:data.surveyOptionId && data.surveyOptionId > 0 ? data.surveyOptionId : null,
    UsuarioId:data.userId && data.userId > 0 ? data.userId : null,
  };
  await privateFetch
    .put(urls.REQUEST_SURVEY, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_survey_update_error,
        false
      );
    });
  return responseData;
};

export const SVDeleteRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);
  await privateFetch
    .delete(urls.REQUEST_SURVEY, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_survey_delete_error,
        false
      );
    });
  return responseData;
};

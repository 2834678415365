import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import IdleTimerContainer from "../components/security/IdleTimerContainer";
import Loading from "../components/utils/Loading";
import Talk from "../pages/dashboard/fairAdmin/room/talk/Talk";
import { properties } from "../utils/Properties_es";
import ResetPassword from "../pages/public/login/ResetPassword";

const PrivateRoute = lazy(() => import("./PrivateRoute"));
const PublicConditionalRouter = lazy(() => import("./PublicConditionalRouter"));
const Layout = lazy(() => import("../components/template/Layout"));

const SecurityProvider = lazy(() =>
  import("../components/security/SecurityProvider")
);

/*********************************************************
 *
 * Public pages
 *
 * *******************************************************/
const ReceptionHome = lazy(() =>
  import("../pages/public/reception/receptionHome/ReceptionHome")
);
const masinformacion = lazy(() =>
  import("../pages/public/reception/receptionHome/Masinformacion")
);
const Login = lazy(() => import("../pages/public/login/Login"));
const Inscription = lazy(() =>
  import("../pages/public/inscription/Inscription")
);
const Perfil = lazy(() => import("../pages/public/perfil/Perfil"));

const Fair = lazy(() => import("../pages/fair/Fair"));

/*********************************************************
 *
 * General pages
 *
 **********************************************************/
const NotFound = lazy(() => import("../pages/utils/NotFound"));

/*********************************************************
 *
 *  Board pages
 *
 *********************************************************/
const BoardHome = lazy(() => import("../pages/dashboard/home/BoardHome"));
const SystemParam = lazy(() =>
  import("../pages/dashboard/fairAdmin/systemParam/SystemParam")
);
const StandCategory = lazy(() =>
  import("../pages/dashboard/fairAdmin/standCategory/StandCategory")
);
const Stand = lazy(() => import("../pages/dashboard/fairAdmin/stand/Stand"));
const Survey = lazy(() =>
  import("../pages/dashboard/fairAdmin/room/survey/Survey")
);
const Gender = lazy(() =>
  import("../pages/dashboard/generalMaintenance/catalogs/gender/Gender")
);
const StudyCenter = lazy(() =>
  import(
    "../pages/dashboard/generalMaintenance/catalogs/studyCenter/StudyCenter"
  )
);
const VisitorType = lazy(() =>
  import(
    "../pages/dashboard/generalMaintenance/catalogs/visitorType/VisitorType"
  )
);
const TalkStatus = lazy(() =>
  import("../pages/dashboard/generalMaintenance/catalogs/talkStatus/TalkStatus")
);
const PhoneType = lazy(() =>
  import("../pages/dashboard/generalMaintenance/catalogs/phoneType/PhoneType")
);

const SocialNetworkType = lazy(() =>
  import(
    "../pages/dashboard/generalMaintenance/catalogs/socialNetworkType/SocialNetworkType"
  )
);
const MultimediaType = lazy(() =>
  import(
    "../pages/dashboard/generalMaintenance/catalogs/multimediaType/MultimediaType"
  )
);
const Provincia = lazy(() =>
  import("../pages/dashboard/generalMaintenance/catalogs/provincias/Provincia")
);

const RegionGeografica = lazy(() =>
  import(
    "../pages/dashboard/generalMaintenance/catalogs/regiongeografica/RegionGeografica"
  )
);
const Canton = lazy(() =>
  import("../pages/dashboard/generalMaintenance/catalogs/canton/Canton")
);
const Distrito = lazy(() =>
  import("../pages/dashboard/generalMaintenance/catalogs/distrito/Distrito")
);
const SurveyOption = lazy(() =>
  import(
    "../pages/dashboard/generalMaintenance/catalogs/surveyOption/SurveyOption"
  )
);
const User = lazy(() => import("../pages/dashboard/security/user/User"));
const Rol = lazy(() => import("../pages/dashboard/security/rol/Rol"));
const Authority = lazy(() =>
  import("../pages/dashboard/security/authority/Authority")
);

/** REPORTS PAGES */
const UserReport = lazy(() =>
  import("../pages/dashboard/reports/userReport/UserReport")
);

const UserBinnacleReport = lazy(() =>
  import("../pages/dashboard/reports/userBinnacleReport/UserBinnacleReport")
);

const UserSurveyReport = lazy(() =>
  import("../pages/dashboard/reports/userSurveyReport/UserSurveyReport")
);

const StandReport = lazy(() =>
import("../pages/dashboard/reports/standReport/StandReport")
);

const TalkReport = lazy(() =>
import("../pages/dashboard/reports/talkReport/TalkReport")
);

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 01/11/2020
 *
 * @description componente padre para manejo de rutas en la app
 * @author lferrer
 * @version 1.0
 *
 ****************************************************************************/
const App = (props) => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={<Loading title={properties.com_feria_label_loading_title} />}
      >
        <SecurityProvider>
          <IdleTimerContainer />
          <Layout>
            <Switch>
              <Route exact path="/" component={ReceptionHome} />
              <Route exact path="/ingresar" component={Login} />
              <Route exact path="/recuperarclave" component={ResetPassword} />
              <Route exact path="/masinformacion" component={masinformacion} />
              <PublicConditionalRouter
                exact
                path="/inscripcion"
                inscription={true}
              >
                <Inscription />
              </PublicConditionalRouter>
              <PublicConditionalRouter exact path="/feria" fair={true}>
                <Fair />
              </PublicConditionalRouter>
              <PrivateRoute exact path="/perfil">
                <Perfil />
              </PrivateRoute>
              {/**INIT DASHBOARD PAGES */}
              <PrivateRoute exact path="/admin">
                <BoardHome />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/parametros">
                <SystemParam />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/standcategory">
                <StandCategory />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/stands">
                <Stand />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/salaprincipal/encuestas">
                <Survey />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/salaprincipal/charlas">
                <Talk />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/generos">
                <Gender />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/centroestudio">
                <StudyCenter />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/tipovisitante">
                <VisitorType />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/tipotelefono">
                <PhoneType />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/tiporedsocial">
                <SocialNetworkType />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/tipomultimedia">
                <MultimediaType />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/estadocharla">
                <TalkStatus />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/opcionencuesta">
                <SurveyOption />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/provincia">
                <Provincia />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/canton">
                <Canton />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/region">
                <RegionGeografica />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/distrito">
                <Distrito />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/usuarios">
                <User />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/roles">
                <Rol />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/autoridades">
                <Authority />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/reporte/usuarios">
                <UserReport />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/reporte/bitacorasusuario">
                <UserBinnacleReport />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/reporte/encuestasusuario">
                <UserSurveyReport />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/reporte/visitastand">
                <StandReport />
              </PrivateRoute>
              <PrivateRoute exact path="/admin/reporte/visitascharla">
                <TalkReport />
              </PrivateRoute>
              
              {/**END DASHBOARD PAGES */}
              <Route path="/desconocido" component={NotFound} />
              <Redirect path="*" to="/desconocido" />
            </Switch>
          </Layout>
        </SecurityProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { constant } from "../../../../../utils/Constant";
import { properties } from "../../../../../utils/Properties_es";
import { validateFieldStyle } from "../../../../../utils/UtilsFunction";

const AddOrUpdateTalkForm = (props) => {
  /**
   *
   * Form states
   *
   */
  const { register, handleSubmit, errors } = useForm({
    defaultValues: props.form,
    mode: "onChange",
  });
  const [applyStyleForm, setApplyStyleForm] = useState(false);

  /**
   * Evento de cambio de estilo en el formulario
   *
   * @author allquiros
   */
  const applyStyleToForm = () => {
    if (!applyStyleForm) {
      setApplyStyleForm(true);
    }
  };

  /**
   * Evento para validar la imagen que se carga
   * @param value
   * @author lferrer
   */
  const validateFileUpload = async (value) => {
    const regex = new RegExp("(.*?).(jpeg|jpg|png)$");
    let file = value[0];
    if (file) {
      //validar el formato del archivo
      if (!regex.test(file.name.toLowerCase())) {
        return false;
      }

      //validar el tamanno del archivo
      if (parseInt(file.size / 1024 / 1024) > constant.MAXIMUM_IMAGE_SIZE) {
        return false;
      }

      //convert file to base64
      const reader = new FileReader();
      reader.onload = handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
    return true;
  };

  /**
   * Evento para convertir la imagen en base64
   * @param readerEvt
   * @author lferrer
   */
  const handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    props.setForm({ ...props.form, talkImagen64: btoa(binaryString) });
  };

  return (
    <div className="pt-3 col-lg-10 m-auto">
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="row justify-content-center">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="name">{properties.com_feria_label_name}</label>
              <input
                type="text"
                className={`form-control ${validateFieldStyle(
                  applyStyleForm,
                  errors.name
                )}`}
                maxLength="50"
                name="name"
                id="name"
                ref={register({ required: true })}
              />
              {errors.name && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_required}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="description">
                {properties.com_feria_label_description}
              </label>
              <textarea
                className={`form-control ${validateFieldStyle(
                  applyStyleForm,
                  errors.description
                )}`}
                maxLength="500"
                name="description"
                id="description"
                ref={register({ required: true })}
              />
              {errors.name && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_required}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="date">
                {properties.com_feria_label_talk_date_title}
              </label>
              <input
                type="datetime-local"
                className={`form-control ${validateFieldStyle(
                  applyStyleForm,
                  errors.date
                )}`}
                name="date"
                id="date"
                ref={register({ required: true })}
              />
              {errors.date && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_required}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="url">
                {properties.com_feria_label_talk_url_title}
              </label>
              <input
                type="text"
                className={`form-control ${validateFieldStyle(
                  applyStyleForm,
                  errors.url
                )}`}
                maxLength="1000"
                name="url"
                id="url"
                ref={register({ required: true })}
              />
              {errors.url && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_required}
                </span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="state">
                {properties.com_feria_label_stand_category}
              </label>
              <select
                className={`form-control ${validateFieldStyle(
                  applyStyleForm,
                  errors.state
                )}`}
                id="state"
                name="state"
                ref={register({
                  required: true,
                  validate: async (value) => parseInt(value) !== 0,
                })}
              >
                <option value={0} key={0}>
                  {properties.com_feria_label_select}
                </option>
                {props.talkstatus &&
                  props.talkstatus.get("Resultado") &&
                  props.talkstatus.get("Resultado").map((r) => (
                    <option value={r.get("Id")} key={r.get("Id")}>
                      {r.get("Codigo")}
                    </option>
                  ))}
              </select>
              {errors.state && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_required}
                </span>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="talkImagen">
                {properties.com_feria_label_talk_imagentalk}
              </label>
              <input
                type="file"
                className={`form-control-file ${validateFieldStyle(
                  applyStyleForm,
                  errors.talkImagen
                )}`}
                name="talkImagen"
                id="talkImagen"
                ref={register(
                  props.formType === "add"
                    ? { required: true, validate: validateFileUpload }
                    : { validate: validateFileUpload }
                )}
                accept=".jpeg, .png, .jpg"
                aria-describedby="fileHelp"
              />
              <small id="fileHelp" className="form-text text-muted">
                {`${
                  props.formType === "add" ? "Ingresar" : "Actualizar"
                } imagen asociada a la charla en la feria, tamaño maximo ${
                  constant.MAXIMUM_IMAGE_SIZE
                } MB y Dimensión (241px x 211px)`}
              </small>
              {errors.talkImagen && errors.talkImagen.type === "required" && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_required}
                </span>
              )}
              {errors.talkImagen && errors.talkImagen.type === "validate" && (
                <span className="invalid-feedback">
                  {properties.com_feria_label_field_img_upload}
                </span>
              )}
            </div>
            {props.form.talkImagen64 && (
              <div className="form-group">
                <button
                  className="btn btn-primary"
                  onClick={props.handleOpenModal}
                  name="previewimage"
                  type="button"
                >
                  {properties.com_feria_label_talk_viewimagentalk}
                </button>
              </div>
            )}
            <div className="modal-footer">
              <button
                className="btn btn-success"
                id="acceptModal"
                onClick={applyStyleToForm}
              >
                {props.formType === "add"
                  ? properties.com_feria_label_button_save_title
                  : properties.com_feria_label_button_update_title}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                id="cancelModal"
                onClick={props.handleCloseForm}
              >
                {properties.com_feria_label_button_cancel_title}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AddOrUpdateTalkForm;

import { urls } from "../../utils/UrlConstant";
import { publicFetch } from "../../utils/PublicFetch";
import { privateFetch } from "../../utils/PrivateFetch";
import qs from "qs";
import { properties } from "../../utils/Properties_es";
export const SVloginRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    Identificacion: data.identificationType + data.identification,
    Password: data.pass,
  };
  await publicFetch
    .post(urls.LOGIN_REQUEST, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error(properties.com_feria_label_login_error);
      }
      throw new Error(properties.com_feria_label_login_general_error);
    });
  return responseData;
};

export const SVloginReset = async (data) => {
  let responseData = {};
  const requestBody = {
    Identificacion: data.identificationType + data.identification
  };
  await publicFetch
    .post(urls.LOGIN_RESET, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        throw new Error(properties.com_feria_label_resetpass_error);
      }
      throw new Error(properties.com_feria_label_resetpass_general_error);
    });
  return responseData;
};

export const SVlogoutRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    BitacoraId: data.bitacoraId,
    DeleteBitacora: data.deleteBitacora && data.deleteBitacora > 0 ? data.deleteBitacora : 0 ,
  };
  await publicFetch
    .post(urls.LOGIN_LOGOUT_REQUEST, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_feria_label_login_logout_error);
    });
  return responseData;
};

export const SVRevalidateTokenRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    Identificacion: data.identification,
  };
  await privateFetch
    .post(urls.LOGIN_REVALIDATE_REQUEST, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_feria_label_login_revalidatetoken_error);
    });
  return responseData;
};

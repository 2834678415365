import { urls } from "../../../utils/UrlConstant";
import { privateFetch } from "../../../utils/PrivateFetch";
import { properties } from "../../../utils/Properties_es";
import qs from "qs";
import { validateResponseError } from "../../others/ValidateServices";
export const SVFindRequest = async (filter, page, allRecords) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["filtro", filter && filter !== "" ? filter : ""],
    ["pagina", page],
    [
      "registroXPagina",
      allRecords ? 0 : process.env.REACT_APP_MODAL_REGISTRATION_X_PAGE,
    ],
  ]);
  await privateFetch
    .get(urls.REQUEST_TALKMEMBER_LIST, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talkmember_find_error,
        false
      );
    });
  return responseData;
};

export const SVFindByIdRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);

  await privateFetch
    .get(urls.REQUEST_TALKMEMBER_ID, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talkmember_findid_error,
        false
      );
    });
  return responseData;
};

export const SVSaveRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    CharlaId: data.talkId,
    NombreCompleto: data.fullname,
    GradoAcademico: data.academicdegree,
    TituloPuesto: data.profession,
  };
  await privateFetch
    .post(urls.REQUEST_TALKMEMBER, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talkmember_save_error,
        false
      );
    });
  return responseData;
};

export const SVUpdateRequest = async (id, data) => {
  let responseData = {};
  const requestBody = {
    Id: id,
    CharlaId: data.talkId,
    NombreCompleto: data.fullname,
    GradoAcademico: data.academicdegree,
    TituloPuesto: data.profession,
  };
  await privateFetch
    .put(urls.REQUEST_TALKMEMBER, qs.stringify(requestBody))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talkmember_update_error,
        false
      );
    });
  return responseData;
};

export const SVDeleteRequest = async (id) => {
  let responseData = {};
  const params = new URLSearchParams([["id", id]]);
  await privateFetch
    .delete(urls.REQUEST_TALKMEMBER, {
      params,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      validateResponseError(
        error,
        properties.com_feria_label_talkmember_delete_error,
        false
      );
    });
  return responseData;
};

export const constant = {
  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  DANGER_MSG: 2,
  WARNING_MSG: 3,

  /** ROLE CONSTANT */
  ADMINISTRADOR: 1,
  STAFF: 2,
  EDITOR: 3,
  PREINSCRITO: 4,
  REGISTRADO: 5,

  /** DEFAULT YES AND NOT */
  DEFAULT_YES: "S",
  DEFAULT_NOT: "N",

  /** IDENTIFICATION TYPE VALUE  */
  IDENTIFICATION_NATIONAL_VALUE: "N",
  IDENTIFICATION_FOREIGN_VALUE: "E",

  /** MAXSIZE OF UPLOAD IMG */
  MAXIMUM_IMAGE_SIZE: 1,

  /** PHONETYPE */
  CODPHONE: 506,
  PHONETYPE_WHATSAPP: "WHATSAPP",
  PHONETYPE_LLAMADA: "LLAMADA",

  /** SOCIALNETWORKTYPE */
  SOCIALTYPE_FACEBOOK: "FACEBOOK",
  SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
  SOCIALTYPE_TWITTER: "TWITTER",
  SOCIALTYPE_LINKEDIN: "LINKEDIN",
  SOCIALTYPE_YOUTUBE: "YOUTUBE",

  /** MULTIMEDIATYPE */
  MULTIMEDIATYPE_IMAGEN: "IMAGEN",
  MULTIMEDIATYPE_VIDEO: "VIDEO",

  
  /** TALKSTATUS */
  TALKSTATUS_SCHEDULED: "CAL",
  TALKSTATUS_FINALLY: "FIN",
};
